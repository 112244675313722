
import theme from "@/theme";
import { sxAjax } from '@/commons/ajax';


// 进行本地存储同步，syncState中的同步是区分用户的，会导致未登录的页面与登录的页面有差异
const getItem = (key) => window.localStorage.getItem(key);
const setItem = (key, value) => window.localStorage.setItem(key, value);

const primaryColor = getItem('primaryColor') || theme['@primary-color'];

export default {
    initialState: {
        loading: false,// 全局loading
        code:0

    },
    getAgreement: {
                payload: ({ params, options }) => sxAjax.get('/common/content/getAgreement', params, options).then(function (resp) {
                    return new Promise((resolve, reject) => {
                        if (resp.code === 0) {
                            resolve(resp.data);
                        } else {
                            reject(resp.msg);
                        }
                    });
                }),
                reducer: {
                    pending: (state, action) => {
                        return { loading: true };
                    },
                    resolve(state, { payload = {} }) {
                        return {
                           adminList: payload
                        }
                    },
                    complete: (state, action) => ({ loading: false }),
                }


            },  contentCourseList: {
                payload: ({ params, options }) => sxAjax.get('/common/content/contentCourseList', params, options).then(function (resp) {
                    return new Promise((resolve, reject) => {
                        if (resp.code === 0) {
                            resolve(resp.data);
                        } else {
                            reject(resp.msg);
                        }
                    });
                }),
                reducer: {
                    pending: (state, action) => {
                        return { loading: true };
                    },
                    resolve(state, { payload = {} }) {
                        return {
                           adminList: payload
                        }
                    },
                    complete: (state, action) => ({ loading: false }),
                }


            },//获取学历 
            getEducationType: {
                payload: ({ params, options }) => sxAjax.get('/common/status/getEducationType', params, options).then(function (resp) {
                    return new Promise((resolve, reject) => {
                        if (resp.code === 0) {
                            resolve(resp.data);
                        } else {
                            reject(resp.msg);
                        }
                    });
                }),
                reducer: {
                    pending: (state, action) => {
                        return { loading: true };
                    },
                    resolve(state, { payload = {} }) {
                        return {
                           adminList: payload
                        }
                    },
                    complete: (state, action) => ({ loading: false }),
                }


            },//获取工作信息
            getWorkType: {
                payload: ({ params, options }) => sxAjax.get('/common/status/getWorkType', params, options).then(function (resp) {
                    return new Promise((resolve, reject) => {
                        if (resp.code === 0) {
                            resolve(resp.data);
                        } else {
                            reject(resp.msg);
                        }
                    });
                }),
                reducer: {
                    pending: (state, action) => {
                        return { loading: true };
                    },
                    resolve(state, { payload = {} }) {
                        return {
                           adminList: payload
                        }
                    },
                    complete: (state, action) => ({ loading: false }),
                }


            },//获取民族
            getMinorityType: {
                payload: ({ params, options }) => sxAjax.get('/common/status/getMinorityType', params, options).then(function (resp) {
                    return new Promise((resolve, reject) => {
                        if (resp.code === 0) {
                            resolve(resp.data);
                        } else {
                            reject(resp.msg);
                        }
                    });
                }),
                reducer: {
                    pending: (state, action) => {
                        return { loading: true };
                    },
                    resolve(state, { payload = {} }) {
                        return {
                           adminList: payload
                        }
                    },
                    complete: (state, action) => ({ loading: false }),
                }


            }
    
    



    
}
