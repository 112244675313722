// 此文件为生成文件，不要直接编辑
import _adminList from 'G:/web/wisdom-party-web/src/models/adminList.js';
import _common from 'G:/web/wisdom-party-web/src/models/common.js';
import _getCommonInfoInfoArr from 'G:/web/wisdom-party-web/src/models/getCommonInfoInfoArr.js';
import _home from 'G:/web/wisdom-party-web/src/models/home.js';
import _menu from 'G:/web/wisdom-party-web/src/models/menu.js';
import _page from 'G:/web/wisdom-party-web/src/models/page.js';
import _partyMemberSignature from 'G:/web/wisdom-party-web/src/models/party-member-signature/partyMemberSignature.js';
import _partyBranchFortIndex from 'G:/web/wisdom-party-web/src/models/partyBranchFortIndex.js';
import _partyBranchList from 'G:/web/wisdom-party-web/src/models/partyBranchList.js';
import _partyBranchOrg from 'G:/web/wisdom-party-web/src/models/partyBranchOrg.js';
import _partyBranchSecretaryList from 'G:/web/wisdom-party-web/src/models/partyBranchSecretaryList.js';
import _partyMemberAdmit from 'G:/web/wisdom-party-web/src/models/partyMemberAdmit.js';
import _partyMemberArchive from 'G:/web/wisdom-party-web/src/models/partyMemberArchive.js';
import _partyMemberBadly from 'G:/web/wisdom-party-web/src/models/partyMemberBadly.js';
import _partyMemberDues from 'G:/web/wisdom-party-web/src/models/partyMemberDues.js';
import _partyMemberPioneerIndex from 'G:/web/wisdom-party-web/src/models/partyMemberPioneerIndex.js';
import _partyMemberTurnover from 'G:/web/wisdom-party-web/src/models/partyMemberTurnover.js';
import _permissions from 'G:/web/wisdom-party-web/src/models/permissions.js';
import _programType from 'G:/web/wisdom-party-web/src/models/programType.js';
import _roles from 'G:/web/wisdom-party-web/src/models/roles.js';
import _routers from 'G:/web/wisdom-party-web/src/models/routers.js';
import _settings from 'G:/web/wisdom-party-web/src/models/settings.js';
import _side from 'G:/web/wisdom-party-web/src/models/side.js';
import _system from 'G:/web/wisdom-party-web/src/models/system.js';
import _usercenter from 'G:/web/wisdom-party-web/src/models/usercenter.js';

export const adminList = _adminList;
export const common = _common;
export const getCommonInfoInfoArr = _getCommonInfoInfoArr;
export const home = _home;
export const menu = _menu;
export const page = _page;
export const partyMemberSignature = _partyMemberSignature;
export const partyBranchFortIndex = _partyBranchFortIndex;
export const partyBranchList = _partyBranchList;
export const partyBranchOrg = _partyBranchOrg;
export const partyBranchSecretaryList = _partyBranchSecretaryList;
export const partyMemberAdmit = _partyMemberAdmit;
export const partyMemberArchive = _partyMemberArchive;
export const partyMemberBadly = _partyMemberBadly;
export const partyMemberDues = _partyMemberDues;
export const partyMemberPioneerIndex = _partyMemberPioneerIndex;
export const partyMemberTurnover = _partyMemberTurnover;
export const permissions = _permissions;
export const programType = _programType;
export const roles = _roles;
export const routers = _routers;
export const settings = _settings;
export const side = _side;
export const system = _system;
export const usercenter = _usercenter;

