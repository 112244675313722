import {sxAjax} from '@/commons/ajax';

export default {
    initialState: {
        loading: false,             // 全局loading
    },
    // 列表
    dataList: {
        payload: ({params, options}) => sxAjax.get('admin/partyMemberDues/getPartyMemberDues', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return {loading: true};
            },
            resolve(state, {payload = {}}) {
            },
            complete: (state, action) => ({loading: false}),
        }
    },//添加/修改
    addOrEdit: {
        payload: ({params, options}) => sxAjax.post('admin/partyMemberDues/addAndEditPartyMemberDues', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return {loading: true};
            },
            resolve(state, {payload = {}}) {
            },
            complete: (state, action) => ({loading: false}),
        }
    },//删除管理员
    del: {
        payload: ({params, options}) => sxAjax.post('admin/partyMemberDues/delPartyMemberDues', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return {loading: true};
            },
            resolve(state, {payload = {}}) {
            },
            complete: (state, action) => ({loading: false}),
        }
    },
    //党费收缴统计
    getPartyMemberDuesCounts: {
        payload: ({params, options}) => sxAjax.get('/admin/partyMemberDuesCount/getPartyMemberDuesCounts', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return {loading: true};
            },
            resolve(state, {payload = {}}) {
            },
            complete: (state, action) => ({loading: false}),
        }
    },
    //党费未缴详情
    getPartyMemberMonthNoPayInfos: {
        payload: ({params, options}) => sxAjax.get('/admin/partyMemberDuesCount/getPartyMemberMonthNoPayInfos', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return {loading: true};
            },
            resolve(state, {payload = {}}) {
            },
            complete: (state, action) => ({loading: false}),
        }
    },

}
