import {sxAjax} from '@/commons/ajax';

export default {
    initialState: {
        loading: true,             // 全局loading
    },
    // 列表
    dataList: {
        payload: ({params, options}) => sxAjax.get('/admin/screenSignature/getPartyMemberSignatures', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return {loading: true};
            },
            resolve(state, {payload = {}}) {
            },
            complete: (state, action) => ({loading: false}),
        }
    },
    del: {
        payload: ({params, options}) => sxAjax.get('/admin/screenSignature/delPartyMemberSignature', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return {loading: true};
            },
            resolve(state, {payload = {}}) {
            },
            complete: (state, action) => ({loading: false}),
        }
    }

}
