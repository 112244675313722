/*
* 菜单数据 返回Promise各式，支持前端硬编码、异步获取菜单数据
* */
export default function getMenus(userId) {
    // const loginUser = getLoginUser();
    // return Promise.resolve(loginUser?loginUser.menus:[]);
    return Promise.resolve([
        {key: 'home', local: 'home', text: '首页', icon: 'home', path: '/', order: 2000},
        //党组织数据库
        {key: '/partyBranchManage', local: 'partyBranchManage', text: '党组织数据库', icon: 'play-square'},
        {
            key: '/partyBranchManage/partyBranch',
            parentKey: '/partyBranchManage',
            icon: 'user',
            local: 'asset',
            text: '党组织设置',
            path: '/partyBranchManage/partyBranch',
        }, {
            key: '/partyBranchManage/partyMemberPioneerIndex',
            parentKey: '/partyBranchManage',
            icon: 'user',
            local: 'asset',
            text: '党员先锋指数',
            path: '/partyBranchManage/partyMemberPioneerIndex',
        }, {
            key: '/partyBranchManage/partyBranchFortIndex',
            parentKey: '/partyBranchManage',
            icon: 'user',
            local: 'asset',
            text: '党支部堡垒数据',
            path: '/partyBranchManage/partyBranchFortIndex',
        },
        //管理
        {key: '/adminManage', local: 'adminManage', text: '管理员管理', icon: 'play-square'},
        {
            key: '/adminManage/adminList',
            parentKey: '/adminManage',
            icon: 'user',
            local: 'asset',
            text: '管理员列表',
            path: '/adminManage/adminList',
        },
        {key: '/partyMember', local: 'systemSet', text: '党员数据库', icon: 'play-square'},
        {
            key: '/partyMember/partyMemberArchiveList',
            parentKey: '/partyMember',
            icon: 'user',
            local: 'asset',
            text: '党员档案数据库',
            path: '/partyMember/partyMemberArchiveList',
        },
        {
            key: '/partyMember/partyMemberTurnover',
            parentKey: '/partyMember',
            icon: 'user',
            local: 'asset',
            text: '流动党员数据库',
            path: '/partyMember/partyMemberTurnover',
        }, {
            key: '/partyMember/partyMemberAdmit',
            parentKey: '/partyMember',
            icon: 'user',
            local: 'asset',
            text: '发展党员数据库',
            path: '/partyMember/partyMemberAdmit',
        }, {
            key: '/partyMember/partyMemberBadly',
            parentKey: '/partyMember',
            icon: 'user',
            local: 'asset',
            text: '困难党员数据库',
            path: '/partyMember/partyMemberBadly',
        }, {
            key: '/partyMember/partyMemberDues',
            parentKey: '/partyMember',
            icon: 'user',
            local: 'asset',
            text: '党费收缴数据库',
            path: '/partyMember/partyMemberDues',
        },
        //签名墙管理
        {key: '/partyMemberSignature', local: 'partyMemberSignature', text: '签名墙管理', icon: 'play-square'},
        {
            key: '/partyMemberSignature/signatureManage',
            parentKey: '/partyMemberSignature',
            icon: 'fund',
            local: 'asset',
            text: '签名管理',
            path: '/partyMemberSignature/signatureManage',
        },
    ]);
}
