import React from 'react';
import AppRouter from './router/AppRouter';
import {connect} from './models';
import Local from './i18n/Local';
import {Affix,Button} from 'antd';
import {getMenuTreeDataAndPermissions, getLoginUser, setLoginUser} from './commons'
import { isTemplateMiddle } from 'typescript';

@connect()
export default class App extends React.Component {
    constructor(...props) {
        super(...props);
        // 从Storage中获取出需要同步到redux的数据
        this.props.action.getStateFromStorage();

        const {system, menu} = this.props.action;
        const loginUser = getLoginUser();
        // let allDataArr = [];
        // function findParentItem(dataArr,parentId,arr){
        //     dataArr.map(item=>{
        //         if(item.id==parentId){
        //             arr.push(item);
        //             return false;
        //         }
        //         if(item.children.length >0){
        //             findParentItem(item.children,parentId,arr);
        //         }
        //     })
        // }
       
        // function getChildrenArr(res,dataArr){
        //     res.map(item=>{
        //         const obj ={};
                
        //         if(item.path!='/'){
        //             obj.key=item.path;
        //         } else {
        //             obj.key='home';
        //             obj.order=999999;
        //         }
        //         obj.text=item.name;
        //         obj.local=item.path;
        //         obj.path=item.path;
        //         if(item.children.length > 0){
        //             getChildrenArr(item.children,dataArr);
        //         }
        //         const parentObjArr = [];
        //         findParentItem(allDataArr,item.parentId,parentObjArr);
        //         if(parentObjArr.length > 0){
        //             obj.parentKey= parentObjArr[0].path;
        //         }
        //         dataArr.push(obj)
                
        //     });
            
        // }
        // 获取系统菜单 和 随菜单携带过来的权限
        this.state.loading = true;
       
 
        menu.getMenus({
            params: {userId: loginUser?.id},
            onResolve: (res) => {
                let menus = res || [];
                const {permissions} = getMenuTreeDataAndPermissions(menus);
                if (loginUser) {
                    loginUser.permissions = permissions;
                    setLoginUser(loginUser);
                }
                // 设置当前登录的用户到model中
                system.setLoginUser(loginUser);
                // 保存用户权限到model中
                system.setPermissions(permissions);
            },
            onComplete: () => {
               this.setState({loading:false})
            },
        });
      
       
    }
    
    state = {
        loading: true,
    };

    render() {
        return (
            <Local>
                <AppRouter/>
            </Local>
        );
    }
}
