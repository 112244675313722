// 此文件是通过脚本生成的，直接编辑无效！！！

// 不需要导航框架的页面路径
export const noFrames = [
    '/login',
];

// 不需要登录就可以访问的页面路径
export const noAuths = [
    '/login',
];

// 需要keep alive 页面
export const keepAlives = [
    {
        path: '/iframe_page_/:src',
        keepAlive: true,
    },
    {
        path: '/login',
        keepAlive: false,
    },
];

// 页面路由配置
export default [
    {
        path: '/adminManage/adminList',
        component: () => import('G:/web/wisdom-party-web/src/pages/admin-manage/admin-list/index.jsx'),
    },
    {
        path: '/',
        component: () => import('G:/web/wisdom-party-web/src/pages/home/index.jsx'),
    },
    {
        path: '/iframe_page_/:src',
        component: () => import('G:/web/wisdom-party-web/src/pages/iframe/index.jsx'),
    },
    {
        path: '/login',
        component: () => import('G:/web/wisdom-party-web/src/pages/login/index.jsx'),
    },
    {
        path: '/partyBranchManage/partyBranch',
        component: () => import('G:/web/wisdom-party-web/src/pages/party-branch-manage/partyBranch/index.jsx'),
    },
    {
        path: '/partyBranchManage/partyBranch',
        component: () => import('G:/web/wisdom-party-web/src/pages/party-branch-manage/partyBranch/partyBranchTree.jsx'),
    },
    {
        path: '/partyBranchManage/partyBranchFortIndex',
        component: () => import('G:/web/wisdom-party-web/src/pages/party-branch-manage/partyBranchFortIndex/index.jsx'),
    },
    {
        path: '/partyBranchManage/partyMemberPioneerIndex',
        component: () => import('G:/web/wisdom-party-web/src/pages/party-branch-manage/partyMemberPioneerIndex/index.jsx'),
    },
    {
        path: '/partyMemberSignature/signatureManage',
        component: () => import('G:/web/wisdom-party-web/src/pages/party-member-signature/signatureManage/index.jsx'),
    },
    {
        path: '/partyMember/partyMemberAdmit',
        component: () => import('G:/web/wisdom-party-web/src/pages/party-member/partyMemberAdmit/index.jsx'),
    },
    {
        path: '/partyMember/partyMemberArchiveList',
        component: () => import('G:/web/wisdom-party-web/src/pages/party-member/partyMemberArchive/index.jsx'),
    },
    {
        path: '/partyMember/partyMemberBadly/addBadlyVisitInfo/:id',
        component: () => import('G:/web/wisdom-party-web/src/pages/party-member/partyMemberBadly/addBadlyVisitInfo.jsx'),
    },
    {
        path: '/partyMember/partyMemberBadly',
        component: () => import('G:/web/wisdom-party-web/src/pages/party-member/partyMemberBadly/index.jsx'),
    },
    {
        path: '/partyMember/partyMemberDues',
        component: () => import('G:/web/wisdom-party-web/src/pages/party-member/partyMemberDues/index.jsx'),
    },
    {
        path: '/partyMember/monthlyPaymentCount',
        component: () => import('G:/web/wisdom-party-web/src/pages/party-member/partyMemberDues/monthlyPaymentCount.jsx'),
    },
    {
        path: '/partyMember/monthlyUnpaidDetails/:itemObj',
        component: () => import('G:/web/wisdom-party-web/src/pages/party-member/partyMemberDues/monthlyUnpaidDetails.jsx'),
    },
    {
        path: '/partyMember/partyMemberTurnover',
        component: () => import('G:/web/wisdom-party-web/src/pages/party-member/partyMemberTurnover/index.jsx'),
    },
    {
        path: '/companyManage/permissionsList',
        component: () => import('G:/web/wisdom-party-web/src/pages/party-member/permissionsList/index.jsx'),
    },
    {
        path: '/permissionManage/permissionList',
        component: () => import('G:/web/wisdom-party-web/src/pages/privilege-manage/permissions/index.jsx'),
    },
    {
        path: '/permissionManage/roles',
        component: () => import('G:/web/wisdom-party-web/src/pages/privilege-manage/roles/index.jsx'),
    },
    {
        path: '/user-center',
        component: () => import('G:/web/wisdom-party-web/src/pages/user-center/UserCenterList.jsx'),
    },
];
